@import "solarized";

@mixin theme( $headerBg, $headerLink, $headerLinkHover, $searchBg, $searchBorder, $searchText, $searchBtn, $searchBtnBg, $sidebarBg, $sidebarLink,
$sidebarLinkHover, $sidebarLinkHoverBg, $tabContentHeading, $notifBg, $notifTabBg, $notifPanelBg, $notifIcon, $notifIconHover, $notifBadgeBg, $notifBadge ) {

  header.app {
    background-color: $headerBg;

    h1 {
      color: $headerLinkHover;
    }

    .header-title {
      .list-inline > li {
        > a {
          color: $sidebarLink;
        }

        > a:hover, & > a:focus {
          color: $sidebarLinkHover;
          background-color: $sidebarLinkHoverBg;
        }

        & > .active {
          color: $sidebarLinkHover;
          background-color: $sidebarLinkHoverBg;
        }
      }
    }

    .sessionInfo > a, .context-menu > a {
      color: $headerLink;

      &:hover, &:focus {
        color: $headerLinkHover;
        text-decoration: none;
      }
    }

    form[role="search"] .input-group {
      .form-control {
        background-color: $searchBg;
        border-color: $searchBorder;
        color: $searchText;

        &::placeholder {
          font-style: italic;
          color: $searchText;
          opacity: 0.7;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .btn-search {
        background-color: $searchBtnBg;
        border-color: $searchBorder;
        color: $searchBtn;
        outline: none;
      }
    }
  }

  .denuded {
    footer {
      background-color: $headerBg;
    }
  }

  #app main .sidebar {
    background-color: $sidebarBg;

    .divider {
      background-color: $sidebarLink !important;
    }

    .nav > li li {
      a {
        border-radius: 0 50px 50px 0;
      }
    }

    .nav > li, .nav > li li {
      color: $sidebarLink;

      > a {
        color: $sidebarLink;

        &:hover, &:focus {
          color: $sidebarLinkHover;
          background-color: $sidebarLinkHoverBg;
        }
      }

      &.active {

        & > a {
          color: $sidebarLinkHover;
          background-color: $sidebarLinkHoverBg;
        }
      }
    }

    .nav.collapsibleNav {
      .active {
        & > a {
          font-weight: bold;
        }
      }
    }

    .nav > li {

      &.active li {
        background-color: darken($sidebarBg, 4%);
      }

      li {
        background-color: $sidebarBg;
      }
    }
  }

  .tabContent {
    h3 {
      color: $tabContentHeading;
    }
  }

  .specialPanel {
    background-color: $notifPanelBg;
  }

  #app main .notifications {
    background-color: $notifBg;

    .tabContent {
      background-color: $notifPanelBg;
    }

    .tabToggle {
      background-color: $notifTabBg;
    }

    .notificationTab, .notificationTab.active .tabToggle {
      background-color: $notifPanelBg;
    }
  }

  .notificationContainer {
    color: $headerLink;

    &:hover, &:focus {
      color: $headerLinkHover;
    }

    .badge {
      background-color: $notifBadgeBg;
      color: $notifBadge;
    }
  }

  .dialogAddStuff, .modal .modal-header {
    background-color: $headerBg;

    .modal-title {
      color: $headerLinkHover;
    }
  }
}

body[data-user-theme="solarized.dark"] {
  @include theme(
    $headerBg: $base03, $headerLink: $base1, $headerLinkHover: $base1-highlight,
    $searchBg: $base02, $searchBorder: $base01, $searchText: $base1-highlight, $searchBtn: $base1-highlight, $searchBtnBg: $base01,
    $sidebarBg: $base03, $sidebarLink: $base1, $sidebarLinkHover: $base1-highlight, $sidebarLinkHoverBg: $base02, $tabContentHeading: #fff,
    $notifBg: $base03, $notifTabBg: $base02, $notifPanelBg: $base02, $notifIcon: $base1, $notifIconHover: $base1-highlight, $notifBadgeBg: $red, $notifBadge: #fff
  );
}
